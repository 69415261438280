import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IDialog } from '../interfaces/IDialog';
import { Dialog } from '../../../../../dataset/Dialog';

@Component({
  templateUrl: './first-time-class-warning-modal.component.html',
  styleUrls: ['./first-time-class-warning-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstTimeClassWarningModalComponent extends Dialog implements IDialog {
  value = false;

  constructor() {
    super();
  }

  closeModal(): void {
    this.resolve(this.value);
  }
}
