import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ChallengeClass } from '../../../../../../dataset/ChallengeClass';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChallengesApiService } from '../../../../../../api/challenges-api/challenges-api.service';
import { ChallengesContextService } from '../../../../../../context/challenges-context/challenges-context.service';
import { ScheduledEvent } from '../../../../../../dataset/ScheduledEvent';
import { Challenge } from '../../../../../../dataset/Challenge';
import { Difficulty } from '../../../../../../dataset/Difficulty';

@UntilDestroy()
@Component({
  selector: 'app-lesson-challenge-downgrade',
  templateUrl: './lesson-challenge-downgrade.component.html',
  styleUrls: ['./lesson-challenge-downgrade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonChallengeDowngradeComponent extends Dialog implements IDialog, OnInit {
  constructor(
    private challengesContext: ChallengesContextService,
    private challengesApiService: ChallengesApiService
  ) {
    super();
  }

  event: ScheduledEvent;
  program: Challenge;
  loading = false;
  complexityLevel?: string;
  level: Difficulty;

  challengeDropToRecommendations(challenge: ChallengeClass): void {
    this.loading = true;
    this.challengesApiService
      .leave(+challenge.challenge_id)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.challengesContext.deleteJoinedChallenge(+challenge.challenge_id);
          this.closeModal(true);
        },
        (err: Error) => {
          this.loading = false;
          alert(err.message);
        }
      );
  }

  ngOnInit(): void {
    this.event = this.data?.event;
    this.program = this.data?.program;

    this.level = this.data.complexity as Difficulty;

    if (this.program) {
      let lastKey: Difficulty[] | Difficulty | undefined = Object.keys(
        this.program?.complexity_levels
      )
        .map(key => +key as Difficulty)
        .filter(key => key < this.level);
      lastKey = lastKey[lastKey?.length - 1];

      if (lastKey !== undefined) {
        this.complexityLevel = this.program?.complexity_levels[lastKey];
      }
    }
  }

  closeModal(data?: unknown): void {
    this.resolve(data);
  }

  downgrade() {
    this.loading = true;
    this.challengesApiService
      .downgrade(this.program.id.toString())
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.closeModal();
        },
        (err: Error) => {
          this.loading = false;
          alert(err.message);
        }
      );
  }
}
