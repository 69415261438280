<app-dialog-container
  (close)="closeModal()"
  [classNames]="'standard'"
  [padding]="'70px 94px'"
  [state]="state">
  <div
    fxLayout="column"
    class="st-h-fill info"
    fxLayoutAlign.sm.custom="center center"
    fxLayoutGap.sm.custom="20px"
    fxLayoutGap="30px">
    <div fxLayout="column">
      <div
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap.sm.custom="20px"
        fxLayoutGap="30px">
        <div
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="20px">
          <h2 class="st-h2">{{ 'Please read' | translate }}</h2>

          <div
            fxLayout="column"
            fxLayoutGap="10px">
            <p class="st-p1 st-center">
              {{
                'At first, you may find it difficult to keep up with the pace of the class. Just try to keep moving without interruption! Start an exercise with the video and listen to the adjustments given by the voice cues. If an exercise is new to your body, it may take some time to figure it out. You may not be able to do the full amount of reps, but don’t feel the need to rush or pause the video every time. Just complete fewer reps and move on. You are already awesome and you’ll rock it soon!'
                  | translate
              }}
            </p>

            <p class="st-p1 st-center">
              {{
                'If a move feels too hard — don’t push it! Everyone’s body is different, so listen carefully to yours. For instance, if you can’t reach your back ankle with both arms in a pigeon, use a strap to help you. If the floor seems too far away in your lunge or splits, use your blocks. Have a great class!'
                  | translate
              }}
            </p>
          </div>
        </div>

        <show-hide-checkbox
          [label]="'Don’t show this again' | translate"
          [(value)]="value" />
      </div>
    </div>

    <button
      class="st-btn st-btn-dark st-weight-700 info-btn st-w-fill"
      (click)="closeModal()">
      {{ 'OK' | translate }}
    </button>
  </div>
</app-dialog-container>
