<app-dialog-container
  [state]="state"
  [padding]="'70px 94px'"
  [backdropClose]="false"
  [classNames]="'standard'"
  (close)="closeModal()">
  <input
    type="file"
    id="media"
    #media
    (change)="fileChangeListener($event)"
    accept="image/*" />

  <div
    fxLayout="column"
    class="info"
    fxLayoutAlign.sm.custom="center center"
    fxLayoutGap="20px">
    <div
      fxLayout="column"
      fxLayoutGap="20px">
      <h2 class="st-h2 st-center">
        {{ 'Photo time' | translate }}
      </h2>

      <p class="st-p1 st-center">
        {{ 'Take a photo and save it to your profile to track your progress.' | translate }}
      </p>
    </div>

    <div
      class="buttons st-w-fill"
      fxLayout="column"
      fxLayoutGap="20px">
      <button
        (click)="upload()"
        class="st-btn st-btn-dark st-weight-700 st-w-fill">
        {{ 'Add a photo' | translate }}
      </button>

      <a
        role="presentation"
        (click)="$event.preventDefault(); resolve(false)"
        class="st-color-dark-grey st-center">
        {{ 'Not now' | translate }}
      </a>
    </div>
  </div>
</app-dialog-container>
