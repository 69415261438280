import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { User } from '../../../../../../dataset/User';
import { Subscription, timer } from 'rxjs';
import { Lesson } from '../../../../../../dataset/Lesson';
import { VideoSource } from '../../../../../../dataset/VideoSource';
import { MakesEvent } from '../../../../../../dataset/MakesEvent';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsApiService } from '../../../../../../api/events-api/events-api.service';
import Player from '@vimeo/player';
import { FirstTimeClassWarningModalComponent } from '../../../../../../shared/components/ui/modals/first-time-class-warning-modal/first-time-class-warning-modal.component';
import { DialogService } from '../../../../../../shared/components/ui/dialog/services/dialog.service';
import { StorageContextService } from '../../../../../../context/storage-context/storage-context.service';
import dayjs from 'dayjs';
import { AppConfiguration } from '../../../../../../core/services/configuration/app.configuration';

export type VideoStates = 'play' | 'pause' | 'stop' | 'unknown';

@UntilDestroy()
@Component({
  selector: 'app-lesson-video',
  templateUrl: './lesson-video.component.html',
  styleUrls: ['./lesson-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonVideoComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('vimeo', { static: false }) vimeo: ElementRef<HTMLDivElement>;

  @Input() user: User | null;
  @Input() lesson: Lesson;
  @Input() eventId: number;

  @Output() completed: EventEmitter<MakesEvent> = new EventEmitter();
  @Output() error: EventEmitter<Error> = new EventEmitter();

  timeLeft = '00:00';
  vimeoMode: boolean;

  video_sources: VideoSource[] = [];

  timer$ = timer(0, 1000);
  timerSubscription?: Subscription;
  secondsCounter = 0;
  sourceChangedVar = false;
  startDate: Date;
  lastSendCalories = 0;
  firstTime = true;

  get calories(): number {
    if (this.user) {
      const calories = this.calculateCalories(this.user.bmr, this.lesson.met, this.secondsCounter);

      if (this.sourceChangedVar) {
        return calories > 0 ? calories - 1 : 0;
      }
      return calories;
    }
    return 0;
  }

  get showCalories(): boolean | 0 | null {
    return (
      this.user &&
      ((this.user?.growth && this.user?.weight) ||
        (this.user?.growth_ft && this.user?.weight_lb)) &&
      !this.firstTime &&
      +this.lesson?.package !== 18
    );
  }

  constructor(
    private appConfig: AppConfiguration,
    private cd: ChangeDetectorRef,
    private eventsApiService: EventsApiService,
    private dialogService: DialogService,
    private storageContext: StorageContextService
  ) {
    // ONLY FOR DEBUG
    if (
      !this.appConfig.production &&
      this.storageContext.getItem('lesson_auto_complete') === 'true'
    ) {
      setTimeout(() => this.ended(), 3000);
    }
  }

  ended(): void {
    const calories = this.calories;

    if (+this.lesson.package !== 18) {
      this.eventsApiService
        .makes(
          this.lesson.id,
          calories - this.lastSendCalories,
          this.startDate,
          this.eventId,
          this.secondsCounter,
          true
        )
        .pipe(untilDestroyed(this))
        .subscribe({
          next: event => {
            this.lastSendCalories = 0;
            this.secondsCounter = 0;
            this.stopTimer();
            this.completed.emit(event);
          },
          error: error => {
            this.error.emit(error);
          },
        });
    }
  }

  ngOnInit(): void {
    this.vimeoMode = !!this.lesson.vimeo_id;
    if (!this.vimeoMode) {
      this.setSources();
    }

    this.cd.detectChanges();

    console.log('LessonVideoComponent ngOnInit', this.lesson);
  }

  ngAfterViewInit(): void {
    console.log('LessonVideoComponent ngAfterViewInit', this.lesson, this.vimeo.nativeElement);

    if (this.vimeoMode) {
      const player = new Player(this.vimeo.nativeElement, {
        id: +this.lesson.vimeo_id,
        byline: false,
        title: false,
      });
      player.on('timeupdate', ({ seconds, duration }) => {
        this.timeLeft = dayjs.utc((duration - seconds) * 1000).format('mm:ss');
        this.cd.detectChanges();
      });
      player.on('ended', () => this.ended());
      player.on('finish', () => this.ended());
      player.on('finished', () => this.ended());
      player.on('play', async () => {
        if (this.firstTime) {
          this.firstTime = false;
          player.pause();
          await this.showFirstTimeClassWarning();
          player.play();
        }
        this.playbackStateChanged('play');
      });
      player.on('bufferstart', async () => {
        if (this.firstTime) {
          this.firstTime = false;
          this.cd.detectChanges();
          player.pause();
          await this.showFirstTimeClassWarning();
          player.play();
        }
      });
      player.on('pause', () => this.playbackStateChanged('pause'));
      player.on('error', err => console.log(err));
      player.on('seeked', ({ seconds, duration }) => {
        if (duration - seconds === 0) {
          player.pause();
          this.ended();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.stopTimer();

    if (this.cd) {
      this.cd.detach();
    }
  }

  private async showFirstTimeClassWarning(): Promise<void> {
    if (!this.storageContext.getItem('first-time-class-warning')) {
      const disableNotifications = await this.dialogService.open(
        FirstTimeClassWarningModalComponent
      );
      if (disableNotifications) {
        this.storageContext.setItem('first-time-class-warning', 'disabled');
      }
    }
  }

  sourceChanged(): void {
    this.sourceChangedVar = true;
  }

  playbackStateChanged(state: VideoStates): void {
    switch (state) {
      case 'play':
        this.resumeTimer();
        if (!this.startDate) {
          this.startDate = new Date();
        }
        break;
      case 'pause':
      case 'stop':
      case 'unknown':
      default:
        this.stopTimer();
        break;
    }
  }

  private setSources(): void {
    this.video_sources = ['480', '720', '1080'].map(size => ({
      label: size,
      url_short: this.lesson.url_streaming_short.replace(/\.mp4$/g, `+${size}.mp4`),
      url_full: this.lesson.url_streaming_full.replace(/\.mp4$/g, `+${size}.mp4`),
    }));
  }

  private stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
  }

  private resumeTimer(): void {
    if (this.timerSubscription) {
      return;
    }
    this.timerSubscription = this.timer$.subscribe(() => {
      this.secondsCounter++;
    });
  }

  private calculateCalories(mbr: number, met: number, seconds: number): number {
    return Math.round((mbr / 24) * met * (seconds / 3600));
  }
}
