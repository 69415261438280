import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import { LoadingModule } from '../ui/loading/loading.module';
import { VideoPlayerControlsModule } from '../video-player-controls/video-player-controls.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VideoPlayerHeaderModule } from './video-player-header/video-player-header.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VideoPlayerComponent],
  imports: [
    CommonModule,
    LoadingModule,
    VideoPlayerControlsModule,
    FlexLayoutModule,
    VideoPlayerHeaderModule,
    TranslateModule,
  ],
  exports: [VideoPlayerComponent],
})
export class VideoPlayerModule {}
