import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { VideoSource } from '../../../dataset/VideoSource';

import { DOCUMENT } from '@angular/common';
import { User } from '../../../dataset/User';
import { FirstTimeClassWarningModalComponent } from '../ui/modals/first-time-class-warning-modal/first-time-class-warning-modal.component';
import { StorageContextService } from '../../../context/storage-context/storage-context.service';
import { DialogService } from '../ui/dialog/services/dialog.service';
import { VideoService } from '../../../core/services/video/video.service';
import dayjs from 'dayjs';
import { TPoint } from '../../../dataset/TPoint';
import { VideoStates } from '../../../dashboard/packages/components/lesson/lesson-video-modal/lesson-video/lesson-video.component';

const SPACE_KEY_CODE = 'Space';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('_video', { static: true }) _video: ElementRef;

  @Input() videoSources: VideoSource[] = [];
  @Input() user: User | null;
  @Input() calories: number;

  @Output() ended: EventEmitter<void> = new EventEmitter();
  @Output() changePlayBackState: EventEmitter<VideoStates> = new EventEmitter();
  @Output() sourceChanged = new EventEmitter();
  @Output() pointReached: EventEmitter<unknown> = new EventEmitter();

  @HostBinding('class.fullscreen')
  fullScreen = false;

  @HostListener('document:keydown', ['$event'])
  spaceKeyDown(event: KeyboardEvent) {
    if (event.code === SPACE_KEY_CODE) {
      event.stopPropagation();
      event.preventDefault();
      this.playPauseHandler();
    }

    if (this.fullScreen) {
      this.clearIdleTimer();
    }
  }

  @HostListener('document:mousemove', ['$event'])
  mouseMoveHandler(): void {
    if (this.fullScreen) {
      this.clearIdleTimer();
    }
  }

  @HostListener('document:fullscreenchange')
  fullScreenChanged(): void {
    this.fullScreen = !!this.document.fullscreenElement;

    if (this.fullScreen) {
      this.setIdleTimer();
    } else {
      clearInterval(this.idleInterval);
    }
  }

  preventSingleClick: boolean;
  clickTimer: ReturnType<typeof setTimeout>;

  playing = false;
  progress = 0;
  tempCurrentTime = 0;
  currentTime = '00:00';
  duration = '00:00';
  videoSource: VideoSource;
  videoSourceSrc: string;
  error: string;
  timeLeft: string;

  idleTime = 0;
  idleInterval: ReturnType<typeof setInterval>;
  hideControls: boolean;

  startOnce = false;
  timeline: TPoint[] = [];

  get video(): HTMLVideoElement {
    return this._video.nativeElement;
  }

  constructor(
    private dialogService: DialogService,
    private storageContext: StorageContextService,
    private host: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    public videoService: VideoService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    console.log('VideoPlayerComponent');

    this.setDefaultVideoSource();
  }

  ngOnDestroy(): void {
    if (this.cd) {
      this.cd.detach();
    }
  }

  onCanPlay(): void {
    this.videoService.setLoading(false);
    this.cd.markForCheck();
  }

  onWaiting(): void {
    this.setPlaybackState('pause');
    this.videoService.setLoading(true);
  }

  onPause(): void {
    this.playing = false;
    this.setPlaybackState('pause');
  }

  onPlaying(): void {
    this.playing = true;
    this.setPlaybackState('play');
  }

  onLoadedMetaData(): void {
    this.video.currentTime = this.tempCurrentTime;
    this.setDuration();

    if (this.playing) {
      this.video.play();
    }
  }

  onTimeUpdate(): void {
    this.setProgress();
    this.setCurrentTime();

    this.videoService.setLoading(false);
    this.cd.detectChanges();
  }

  onLoadStart(): void {
    this.setCurrentTime();
    this.videoService.setLoading(true);
    this.cd.markForCheck();
  }

  onEnded(): void {
    this.setPlaybackState('stop');
    this.ended.emit();
  }

  backHandler(): void {
    this.video.currentTime -= 15;
    this.setCurrentTime();
    this.setProgress();
  }

  forwardHandler(): void {
    this.video.currentTime += 15;
    this.setCurrentTime();
    this.setProgress();
  }

  fullScreenHandler(value: boolean) {
    this.fullScreen = value;
    !this.fullScreen ? this.document.exitFullscreen() : this.host.nativeElement.requestFullscreen();
  }

  playPauseHandler(): void {
    this.preventSingleClick = false;
    const delay = 200;
    this.clickTimer = setTimeout(() => {
      if (!this.preventSingleClick) {
        this.video.paused ? this.video.play() : this.video.pause();
      }
    }, delay);
  }

  selectSource(source: VideoSource): void {
    this.videoService.setLoading(true);

    setTimeout(() => {
      this.tempCurrentTime = this.video.currentTime;
      this.sourceChanged.emit(true);

      this.videoSource = source;
      this.videoSourceSrc = this.videoSource.url_full;
      this.cd.detectChanges();
    });
  }

  endCurrentTimeHandler(): void {
    this.video.currentTime = this.video.duration;
    this.setCurrentTime();
    this.setProgress();
  }

  startCurrentTimeHandler(): void {
    this.video.currentTime = 0;
    this.setCurrentTime();
    this.setProgress();
  }

  changeCurrentTimeHandler(x: number): void {
    this.video.currentTime = this.video.duration * x;
    this.setCurrentTime();
    this.setProgress();
  }

  onLoadingChange(val: boolean) {
    this.videoService.setLoading(val);
    this.cd.detectChanges();
  }

  async playVideo(): Promise<void> {
    if (!this.startOnce) {
      await this.showFirstTimeClassWarning();
    }

    await this.video.play();
  }

  onError(err: ErrorEvent) {
    this.error = this.videoService.errorHandler(err);
    this.videoService.setLoading(false);
    this.cd.detectChanges();
  }

  doubleClickHandler(): void {
    this.preventSingleClick = true;
    clearTimeout(this.clickTimer);
    this.fullScreenHandler(!this.fullScreen);
  }

  clearIdleTimer(): void {
    this.idleTime = 0;
    this.hideControls = false;
  }

  setIdleTimer(): void {
    this.idleInterval = setInterval(this.idleTimerIncrement.bind(this), 1000);
  }

  idleTimerIncrement(): void {
    this.idleTime++;

    if (this.idleTime >= 5 && !this.hideControls) {
      this.hideControls = true;
    }
  }

  private setCurrentTime(): void {
    if (this.video.currentTime) {
      this.currentTime = dayjs.utc(this.video.currentTime * 1000).format('mm:ss');
      this.timeLeft = dayjs
        .utc((this.video.duration - this.video.currentTime) * 1000)
        .format('mm:ss');
    }
  }

  private setProgress(): void {
    this.progress = (this.video.currentTime / this.video.duration) * 100 || 0;
  }

  private setDuration(): void {
    this.duration = dayjs.utc(this.video.duration * 1000).format('mm:ss');
  }

  private setDefaultVideoSource(): void {
    if (this.videoSources.length) {
      this.videoSource = this.videoSources[1] as VideoSource;

      if (this.videoSource) {
        this.videoSourceSrc = this.videoSource.url_full;
      }
    }
  }

  private setPlaybackState(value: VideoStates): void {
    this.changePlayBackState.emit(value);
  }

  private async showFirstTimeClassWarning(): Promise<void> {
    this.startOnce = true;
    if (!this.storageContext.getItem('first-time-class-warning')) {
      const disableNotifications = await this.dialogService.open(
        FirstTimeClassWarningModalComponent
      );

      if (disableNotifications) {
        this.storageContext.setItem('first-time-class-warning', 'disabled');
      }
    }
  }
}
