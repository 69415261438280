import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerControlsComponent } from './video-player-controls.component';
import { IconModule } from '../ui/icon/icon.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomDirectiveModule } from '../../directives/custom-layout-directive/custom-flex-directive.module';

@NgModule({
  declarations: [VideoPlayerControlsComponent],
  imports: [CommonModule, IconModule, FlexLayoutModule, CustomDirectiveModule],
  exports: [VideoPlayerControlsComponent],
})
export class VideoPlayerControlsModule {}
