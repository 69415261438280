<div class="meta text-center">
  <span class="duration">
    <icon name="time" />

    <span
      [stInterpolate]="'[minutes] min' | translate"
      [params]="{ minutes: event.lesson.minutes }"></span>
  </span>

  <span class="level">
    <icon name="flame" />

    <span
      [stInterpolate]="'[calories] calories' | translate"
      [params]="{ calories: event.calories }"></span>
  </span>

  <span class="points">
    <icon name="star" />

    <span
      [stInterpolate]="'[points] points' | translate"
      [params]="{ points: event.lesson.duration }"></span>
  </span>
</div>

<h1 class="section-title text-center">
  {{ 'Great! How did it feel?' | translate }}
</h1>

<div class="variants layout-row">
  <div
    role="presentation"
    class="variant flex"
    *ngFor="let v of variants"
    ngForTrackById
    (click)="select(v)"
    [class.active]="selected(v.value)">
    {{ v.label }}
  </div>
</div>

<textarea
  [(ngModel)]="comment"
  [placeholder]="'Comment (Optional)' | translate"></textarea>

<div class="text-center">
  <button
    class="button dark"
    (click)="submit()"
    [disabled]="!variant">
    {{ 'Submit' | translate }}
  </button>
</div>
