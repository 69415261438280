import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  Recommendation,
  RecommendationRequestResult,
} from '../../../../../../dataset/Recommendation';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { TimeService } from '../../../../../../core/services/time/time.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-lesson-recommendation',
  templateUrl: './lesson-recommendation.component.html',
  styleUrls: ['./lesson-recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonRecommendationComponent extends Dialog implements IDialog, OnInit, OnDestroy {
  loading = false;
  today = dayjs().startOf('day').toDate();
  timeEnabled = true;

  recommendationsResult: RecommendationRequestResult;
  selectedRecommendation: Recommendation;

  timeControl: UntypedFormControl = new UntypedFormControl(this.timeService.getCurrentTime());

  public timeout: ReturnType<typeof setTimeout>;

  form = this.fb.group({
    date: [new Date(), [Validators.required]],
  });

  get date() {
    return this.form.get('date');
  }

  constructor(
    public timeService: TimeService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedRecommendation = this.data.selectedRecommendation;
    this.recommendationsResult = this.data.recommendationsResult;

    if (!this.selectedRecommendation && this.recommendationsResult.recommendations?.length === 1) {
      this.timeout = setTimeout(() => {
        this.recommendationsResult.recommendations?.[0] &&
          this.selectRecommendation(this.recommendationsResult.recommendations?.[0]);
      });
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  timeEnableToggle(): void {
    this.timeControl.setValue(this.timeService.getCurrentTime());
  }

  closeModal(data?: unknown): void {
    this.resolve(data);
  }

  selectRecommendation(recommendation: Recommendation): void {
    this.selectedRecommendation = recommendation;
  }

  addToSchedule(): void {
    this.loading = true;
    const date = dayjs(this.date?.value).format('YYYYMMDD');
    const time = dayjs(this.timeControl.value.value).format('HHmmss');

    let outputDate;

    if (!this.timeEnabled) {
      outputDate = dayjs(date).endOf('day').toDate();
    } else {
      outputDate = dayjs(date + time, 'YYYYMMDD[T]HHmmss').format('YYYYMMDD[T]HHmmss');
    }

    this.selectedRecommendation.recommended_start_date = outputDate as Date;
    this.closeModal(this.selectedRecommendation);
  }
}
