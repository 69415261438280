<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <div
    class="loading"
    *ngIf="loading"></div>

  <app-loading *ngIf="loading" />

  <div
    class="info st-w-fill"
    fxLayoutGap="40px"
    fxLayoutAlign.sm.custom="center center"
    fxLayout="column">
    <div
      fxLayoutGap="40px"
      fxLayoutGap.sm.custom="20px"
      fxLayout="column">
      <h2 class="st-h2 st-center st-w-fill">
        {{ 'Schedule your next training' | translate }}
      </h2>

      <div
        fxLayout="column"
        fxLayoutGap="20px">
        <app-recommended-package
          *ngFor="let recommendation of recommendationsResult.recommendations"
          [class.active]="selectedRecommendation === recommendation"
          (click)="selectRecommendation(recommendation)"
          [lesson]="recommendation.lesson"
          ngForTrackById />

        <div
          fxLayout="column"
          [formGroup]="form"
          fxLayoutGap="20px">
          <div
            fxLayout="row"
            fxLayoutGap="15px">
            <app-date-input
              fxFlex="1 1"
              class="st-w-fill"
              formControlName="date"
              [label]="'Pick a date' | translate"
              [placeholder]="'Choose a date' | translate"
              [new]="true"
              [middle]="true"
              [up]="false"
              [min]="today" />

            <st-form-field
              fill
              *ngIf="timeEnabled"
              [label]="'Set reminders' | translate">
              <st-dropdown
                [items]="timeService.times"
                [formControl]="timeControl"
                [placeholder]="'12:00 am'" />
            </st-form-field>
          </div>

          <show-hide-checkbox
            [label]="'Reminders ON' | translate"
            (valueChange)="timeEnableToggle()"
            [(value)]="timeEnabled" />

          <p class="st-p1 st-color-dark-grey">
            {{
              '* To get reminders about your training please make sure to install the STRETCHIT app and turn on notifications in your training settings'
                | translate
            }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="buttons st-w-fill"
      fxLayout="column"
      fxLayoutGap="20px">
      <button
        class="st-btn st-btn-dark st-weight-700 st-w-fill"
        (click)="addToSchedule()"
        [disabled]="!selectedRecommendation">
        {{ 'Done' | translate }}
      </button>

      <a
        role="presentation"
        class="st-center st-color-dark-grey st-pointer"
        (click)="$event.preventDefault(); closeModal(false)">
        {{ 'Not now' | translate }}
      </a>
    </div>
  </div>
</app-dialog-container>
