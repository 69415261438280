import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Lesson } from '../../../../../../dataset/Lesson';
import { LessonUtils } from '../../../../../../context/lessons-context/lesson-utils';

@Component({
  selector: 'app-recommended-package',
  templateUrl: './recommended-package.component.html',
  styleUrls: ['./recommended-package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendedPackageComponent {
  private _lesson: Lesson;
  @Input() set lesson(value: Lesson) {
    if (value) {
      this._lesson = LessonUtils.perform(value);
    }
  }

  get lesson(): Lesson {
    return this._lesson;
  }
}
