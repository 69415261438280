import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Value } from '../../../../../../dataset/Value';
import { RecommendationsRequiredOptions } from '../../../../../../dataset/Recommendation';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';

@Component({
  selector: 'app-lesson-required-params',
  templateUrl: './lesson-required-params.component.html',
  styleUrls: ['./lesson-required-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonRequiredParamsComponent extends Dialog implements IDialog, OnInit, OnDestroy {
  duration?: Value<number>;
  durations: Value<number>[] = [];

  get disabled(): boolean {
    return !!(
      this.data.requiredParams.duration &&
      this.data.requiredParams.duration.length &&
      !this.duration
    );
  }

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.data.requiredParams.duration) {
      this.durations = this.data.requiredParams.duration.map((min: number) => ({
        label: `${min} min`,
        value: min,
      }));
    }

    if (this.data.requiredOptions && this.data.requiredOptions.duration) {
      this.duration = this.durations.find(d => d.value === this.data.requiredOptions.duration);
    }
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.cd) {
      this.cd.detach();
    }
  }

  closeModal(data?: unknown): void {
    this.resolve(data);
  }

  getRecommendations(): void {
    if (this.disabled) {
      return;
    }

    const options: RecommendationsRequiredOptions = {};

    if (this.data.requiredParams.duration && this.data.requiredParams.duration.length) {
      options.duration = this.duration?.value;
    }
    this.closeModal(options);
  }

  setDuration(duration: Value<number>): void {
    this.duration = duration;
  }
}
