<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <div
    class="info"
    fxLayoutGap="20px"
    fxLayoutAlign.sm.custom="center center"
    fxLayout="column">
    <div
      fxLayout="column"
      class="st-w-fill"
      fxLayoutGap="40px">
      <h2 class="st-h2 st-center st-w-fill">
        {{ 'Schedule your next training' | translate }}
      </h2>

      <div
        fxLayout="column"
        class="st-w-fill"
        fxLayoutAlign="start center"
        fxLayoutGap="20px">
        <div
          *ngIf="durations.length"
          fxLayout="column"
          class="st-w-fill"
          fxLayoutAlign="start center"
          fxLayoutGap="20px">
          <h4 class="st-h4">
            {{ 'Choose your availability' | translate }}
          </h4>

          <div
            class="info-options st-w-fill"
            fxLayout="column"
            fxLayoutGap="10px">
            <div
              role="presentation"
              *ngIf="durations[0] as d"
              class="st-center info-option"
              [class.active]="duration === d"
              (click)="setDuration(d)">
              {{ 'Up to 15 min' | translate }}
            </div>

            <div
              role="presentation"
              *ngIf="durations[1] as d"
              class="st-center info-option"
              [class.active]="duration === d"
              (click)="setDuration(d)">
              {{ '16-30 min' | translate }}
            </div>

            <div
              role="presentation"
              *ngIf="durations[2] as d"
              class="st-center info-option"
              [class.active]="duration === d"
              (click)="setDuration(d)">
              {{ 'Over 30 min' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="buttons st-w-fill"
      fxLayout="column"
      fxLayoutGap="20px"
      fxLayoutAlign="start center">
      <button
        class="st-btn st-btn-dark st-weight-700 st-w-fill"
        (click)="getRecommendations()"
        [disabled]="disabled">
        {{ 'Get my class' | translate }}
      </button>

      <a
        href="#"
        (click)="$event.preventDefault(); closeModal(false)"
        class="st-center st-color-dark-grey">
        {{ 'Not now' | translate }}
      </a>
    </div>
  </div>
</app-dialog-container>
