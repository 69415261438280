import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerHeaderComponent } from './video-player-header.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [VideoPlayerHeaderComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [VideoPlayerHeaderComponent],
})
export class VideoPlayerHeaderModule {}
