<div
  class="package-lesson"
  fxLayout="column"
  fxLayoutGap="15px">
  <div class="lesson-image">
    <img
      [src]="lesson.image16x11.original"
      [alt]="lesson.name" />
  </div>

  <div
    fxLayout="column"
    class="package-lesson-info"
    fxLayoutGap="5px">
    <div
      class="lesson-name"
      fxLayout="row"
      fxLayoutGap="5px"
      fxLayoutAlign="space-between center">
      <div
        class="lesson-props"
        fxLayout="column"
        fxLayoutGap="5px">
        <h4 class="st-h4 lesson-name">{{ lesson.name | translate }}</h4>

        <p
          class="st-color-dark-grey lesson-slogan"
          ellipsis
          >{{ lesson.slogan | translate }}</p
        >
      </div>
    </div>

    <app-lesson-properties [lesson]="lesson" />
  </div>
</div>
