<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <app-loading *ngIf="loading"></app-loading>
  <div
    class="loading"
    *ngIf="loading"></div>
  <div
    class="info"
    fxLayoutGap="20px"
    fxLayout="column">
    <div
      class="st-w-fill"
      fxLayout="column"
      fxLayoutGap="20px">
      <h2 class="st-center st-h2">
        {{ 'Let`s fix this' | translate }}
      </h2>

      <p class="st-p1 st-center">
        {{
          'Please don’t push yourself too hard and amend your training schedule if necessary'
            | translate
        }}
      </p>
    </div>

    <div class="buttons st-w-fill">
      <div
        fxLayout="column"
        fxLayoutGap="20px">
        <ng-container *ngIf="complexityLevel">
          <button
            class="st-btn st-btn-dark st-weight-700 st-w-fill"
            (click)="downgrade()">
            <span
              [stInterpolate]="'Switch to [complexity]' | translate"
              [params]="{ complexity: complexityLevel }"></span>
          </button>
        </ng-container>

        <button
          class="st-btn st-btn-dark st-weight-700 st-w-fill"
          (click)="closeModal()">
          {{ 'Continue challenge' | translate }}
        </button>

        <button
          *ngIf="event.challenge_class"
          class="st-btn st-center st-pointer st-weight-700"
          (click)="challengeDropToRecommendations(event.challenge_class)">
          {{ 'Switch to personal recommendations' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-dialog-container>
