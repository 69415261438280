<app-dialog-container
  [state]="state"
  [fromVideo]="true"
  [disableClose]="isVideo"
  [backdropClose]="isVideo"
  (close)="closeModal()"
  [classNames]="'no-padding big'">
  <app-loading *ngIf="loading"></app-loading>

  <!--play video -->
  <app-lesson-video
    *ngIf="isVideo"
    [lesson]="lesson"
    [eventId]="eventId"
    [user]="user"
    (close)="closeModal()"
    (completed)="classCompleted($event)">
  </app-lesson-video>

  <!--send feedback-->
  <app-lesson-feedback
    *ngIf="isFeedback"
    [event]="makesEvent.event"
    (complete)="feedbackComplete($event)">
  </app-lesson-feedback>
  <!--provide additional info-->
  <app-lesson-required-params
    *ngIf="isRequiredParams"
    (close)="closeModal()">
    <!--  <app-lesson-required-params-->
    <!--    *ngIf="isRequiredParams"-->
    <!--    [requiredParams]="requiredParams"-->
    <!--    [requiredOptions]="requiredOptions"-->
    <!--    (close)="closeModal()"-->
    <!--    (complete)="requiredParamsComplete($event)">-->
  </app-lesson-required-params>
  <!--show recommendation-->
  <app-lesson-recommendation
    *ngIf="isRecommendation"
    (close)="closeModal()">
    <!--  <app-lesson-recommendation-->
    <!--    *ngIf="isRecommendation"-->
    <!--    [recommendationsResult]="recommendationsResult"-->
    <!--    [(selectedRecommendation)]="selectedRecommendation"-->
    <!--    (close)="closeModal()"-->
    <!--    (complete)="recommendationComplete()">-->
  </app-lesson-recommendation>
  <!--challenge photo-->
  <app-lesson-challenge-photo *ngIf="isChallengePhoto"></app-lesson-challenge-photo>
  <!-- challenge complete -->
  <app-lesson-challenge-complete *ngIf="isChallengeComplete"></app-lesson-challenge-complete>
  <!-- challenge downgrade -->
  <app-lesson-challenge-downgrade *ngIf="isChallengeDowngrade"></app-lesson-challenge-downgrade>
  <!--done-->
  <app-lesson-done *ngIf="isDone"></app-lesson-done>
  <!--  <app-lesson-done-->
  <!--    *ngIf="isDone"-->
  <!--    (complete)="closeModal()"-->
  <!--    [event]="event">-->
  <!--  </app-lesson-done>-->
  <div
    class="loading"
    *ngIf="loading"></div>
</app-dialog-container>
