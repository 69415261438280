<div
  class="vimeo-container"
  #vimeo
  *ngIf="vimeoMode">
  <div
    class="timeLeft"
    *ngIf="!firstTime"
    >{{ timeLeft }}</div
  >
  <div
    class="calories"
    fxLayoutAlign="start center"
    *ngIf="showCalories"
    fxLayoutGap="10px"
    fxLayout="row">
    <svg
      width="19"
      height="24"
      class="st-stroke-black">
      <use xlink:href="#flame"></use>
    </svg>
    <div class="calories-value">
      {{ calories ? calories : 0 }}
    </div>
  </div>
</div>
<div
  class="video"
  *ngIf="!vimeoMode">
  <video-player
    (changePlayBackState)="playbackStateChanged($event)"
    [user]="user"
    [calories]="calories"
    [videoSources]="video_sources"
    (ended)="ended()"
    (sourceChanged)="sourceChanged()">
  </video-player>
</div>
