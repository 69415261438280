<app-dialog-container
  [state]="state"
  [classNames]="'without-padding standard white-close'"
  (close)="closeModal()">
  <img
    src="https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/upgrade-to-premium/upgrade-to-premium.png"
    srcset="
      https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/upgrade-to-premium/upgrade-to-premium.png    1x,
      https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/upgrade-to-premium/upgrade-to-premium@2x.png 2x
    "
    alt="Upgrade to premium"
    class="upgrade-modal__banner upgrade-modal__banner--desktop" />

  <img
    src="https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/upgrade-to-premium/upgrade-to-premium-mobile.png"
    srcset="
      https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/upgrade-to-premium/upgrade-to-premium-mobile.png    1x,
      https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/upgrade-to-premium/upgrade-to-premium-mobile@2x.png 2x
    "
    alt="Upgrade to premium"
    class="upgrade-modal__banner upgrade-modal__banner--mobile" />

  <div class="upgrade-modal">
    <h3 class="st-h3 text-center"> {{ 'All-Access Subscription Benefits' | translate }} </h3>

    <ul class="upgrade-modal__info">
      <li class="upgrade-modal__point">
        {{ 'Get unlimited access to ALL classes on the app' | translate }}
      </li>

      <li class="upgrade-modal__point">
        {{ 'Join easy-to-follow programs to get fit and flexible' | translate }}
      </li>

      <li class="upgrade-modal__point">
        {{ 'Enjoy new workouts WEEKLY' | translate }}
      </li>

      <li class="upgrade-modal__point">
        {{ '8/10 STRETCHIT premium users reach their goals faster' | translate }}
      </li>
    </ul>

    <div class="upgrade-modal__buttons">
      <button
        (click)="closeModal('upgrade')"
        class="st-btn st-btn-dark st-weight-700 st-w-fill">
        {{ 'Upgrade to premium' | translate }}
      </button>

      <button
        (click)="closeModal('restart')"
        class="st-btn st-weight-700 st-w-fill">
        {{ 'Restart program*' | translate }}
      </button>

      <p class="st-p1 st-color-dark-grey text-center">
        {{
          '*Keep your current $4.99/mo subscription and complete the Active Sweat Program again'
            | translate
        }}
      </p>
    </div>
  </div>
</app-dialog-container>
