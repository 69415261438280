import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { Lesson } from '../../../../../../dataset/Lesson';

@Component({
  selector: 'app-lesson-done',
  templateUrl: './lesson-done.component.html',
  styleUrls: ['./lesson-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonDoneComponent extends Dialog implements IDialog, OnInit {
  closeModal(data?: unknown): void {
    this.resolve(data);
  }

  lesson: Lesson;
  startDate: Date;

  ngOnInit(): void {
    this.lesson = this.data.lesson;
    this.startDate = this.data.startDate;
  }
}
