<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <div
    class="info"
    fxLayoutGap="20px"
    fxLayoutAlign.sm.custom="center center"
    fxLayout="column">
    <div
      fxLayout="column"
      fxLayoutGap="20px">
      <h2 class="st-h2 st-center">
        {{ 'Success' | translate }}
      </h2>

      <p class="st-p1 st-center">
        <span
          [stInterpolate]="'[lesson_name] has been scheduled for' | translate"
          [params]="{ lesson_name: (lesson ? lesson.name : 'Test class') | translate }"></span>
        <span *ngIf="lesson"> {{ startDate | parseDate | date: 'd MMMM, Y' }}. </span>
        <br />
        {{ 'You can start your training from the Home screen' | translate }}
      </p>
    </div>

    <div class="buttons st-w-fill">
      <button
        class="st-btn st-w-fill st-btn-dark st-weight-700"
        (click)="closeModal()">
        {{ 'OK' | translate }}
      </button>
    </div>
  </div>
</app-dialog-container>
