import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';

@Component({
  selector: 'app-lesson-challenge-photo',
  templateUrl: './lesson-challenge-photo.component.html',
  styleUrls: ['./lesson-challenge-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonChallengePhotoComponent extends Dialog implements IDialog {
  closeModal(): void {
    this.resolve(false);
  }

  @ViewChild('media', { static: true })
  mediaInput: ElementRef;

  upload(): void {
    this.mediaInput.nativeElement.click();
  }

  fileChangeListener($event: Event): void {
    const file = ($event.target as HTMLInputElement)?.files?.[0];
    this.mediaInput.nativeElement.value = '';
    this.resolve(file);
  }
}
