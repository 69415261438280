<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <app-loading *ngIf="loading"></app-loading>

  <div
    class="loading"
    *ngIf="loading"></div>

  <ng-container *ngIf="possibleNextLevel; else premiumUpgrade">
    <div
      class="info"
      fxLayoutGap="20px"
      fxLayout="column">
      <div
        class="st-w-fill"
        fxLayout="column"
        fxLayoutGap="20px">
        <h2 class="st-center st-h2">
          {{ 'Sorry to hear that' | translate }}
        </h2>

        <p class="st-p1 st-center">
          {{
            (currentLevel < possibleNextLevel
              ? 'Would you like to amend your training schedule?'
              : 'Please don’t push yourself too hard. You can amend your training schedule if you’d like'
            ) | translate
          }}
        </p>
      </div>
      <div class="buttons st-w-fill">
        <div
          fxLayout="column"
          fxLayoutGap="20px">
          <button
            class="st-btn st-weight-700 st-w-fill"
            (click)="changeLevel()">
            <span
              [stInterpolate]="'Switch to [complexity]' | translate"
              [params]="{ complexity: possibleLevelTitle }"></span>
          </button>

          <a
            role="presentation"
            (click)="$event.preventDefault(); closeModal()"
            class="st-color-dark-grey st-center buttons-link">
            {{ 'Continue Program' | translate }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #premiumUpgrade>
    <div
      class="info"
      fxLayoutGap="20px"
      fxLayout="column">
      <div
        class="st-w-fill"
        fxLayout="column"
        fxLayoutGap="20px">
        <h2 class="st-center st-h2">
          {{ 'Upgrade to premium' | translate }}
        </h2>

        <p class="st-p1 st-center">
          {{
            'Would you like to upgrade to an All-Access Subscription to get access to ALL programs on STRETCHIT? We recommend you complete one of our Absolute Beginner courses before continuing your Active Sweat training'
              | translate
          }}
        </p>
      </div>

      <div class="buttons st-w-fill">
        <div
          fxLayout="column"
          fxLayoutGap="20px">
          <button
            class="st-btn st-weight-700 st-w-fill"
            (click)="closeModal('upgrade')">
            {{ 'Upgrade' | translate }}
          </button>

          <a
            role="presentation"
            (click)="$event.preventDefault(); closeModal()"
            class="st-color-dark-grey st-center buttons-link">
            {{ 'Continue Program' | translate }}
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</app-dialog-container>
