import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChallengesApiService } from '../../../../../../api/challenges-api/challenges-api.service';
import { ScheduledEvent } from '../../../../../../dataset/ScheduledEvent';
import { Challenge } from '../../../../../../dataset/Challenge';
import { Difficulty } from '../../../../../../dataset/Difficulty';

@UntilDestroy()
@Component({
  selector: 'app-lesson-challenge-level-premium',
  templateUrl: './lesson-challenge-level-premium.component.html',
  styleUrls: ['./lesson-challenge-level-premium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonChallengeLevelPremiumComponent extends Dialog implements IDialog, OnInit {
  constructor(private challengesApiService: ChallengesApiService) {
    super();
  }

  event: ScheduledEvent;
  program: Challenge;
  loading = false;
  currentLevel: Difficulty;
  possibleNextLevel: number;
  possibleLevelTitle: string | null;
  feedback: string;

  ngOnInit(): void {
    this.event = this.data?.event;
    this.program = this.data?.program;
    this.feedback = this.data?.feedback;

    this.currentLevel = this.data.complexity as Difficulty;

    if (this.program) {
      const keys = Object.keys(this.program?.complexity_levels).map(key => +key as Difficulty);

      let lastKey: Difficulty[] | Difficulty | null = keys.filter(key => this.comparator(key));
      lastKey = lastKey[lastKey.length - 1] ?? null;

      if (lastKey) {
        this.possibleNextLevel = lastKey;
        this.possibleLevelTitle = this.program?.complexity_levels?.[lastKey] ?? null;
      }
    }
  }

  closeModal(data?: any): void {
    this.resolve(data);
  }

  changeLevel() {
    this.loading = true;

    (this.currentLevel < this.possibleNextLevel
      ? this.challengesApiService.upgrade(this.program.id.toString())
      : this.challengesApiService.downgrade(this.program.id.toString())
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.closeModal();
        },
        (err: Error) => {
          this.loading = false;
          alert(err.message);
        }
      );
  }

  private comparator(key: Difficulty) {
    if (this.feedback === 'hard') {
      return key < this.currentLevel;
    }

    if (this.feedback === 'easy') {
      return key > this.currentLevel;
    }

    return false;
  }
}
