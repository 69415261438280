import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { User } from '../../../../../dataset/User';
import {
  Recommendation,
  RecommendationRequestResult,
  RecommendationsRequiredOptions,
  RequiredParams,
} from '../../../../../dataset/Recommendation';
import { StateClass } from '../../../../../dataset/StateClass';
import { ScheduledEvent } from '../../../../../dataset/ScheduledEvent';
import { MakesEvent } from '../../../../../dataset/MakesEvent';
import { LessonFeedback } from '../../../../../dataset/LessonFeedback';
import { UserContextService } from '../../../../../context/user-context/user-context.service';
import { Dialog } from '../../../../../dataset/Dialog';
import { IDialog } from '../../../../../shared/components/ui/modals/interfaces/IDialog';
import { combineLatest, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Lesson } from '../../../../../dataset/Lesson';
import { catchError, map, tap } from 'rxjs/operators';
import { RecommendationsApiService } from '../../../../../api/recommendations-api/recommendations-api.service';
import keyBy from 'lodash/keyBy';
import { PackagesContextService } from '../../../../../context/packages-context/packages-context.service';
import { BuyContent } from '../../../../profile/profile-subscriptions/profile-subscriptions.component';
import { BuyModalComponent } from '../../../../../shared/components/ui/modals/buy-modal/buy-modal.component';
import { DialogService } from '../../../../../shared/components/ui/dialog/services/dialog.service';
import { Package } from '../../../../../dataset/Package';
import { Subscription } from '../../../../../dataset/Subscription';
import { InfoModalComponent } from '../../../../../shared/components/ui/modals/info-modal/info-modal.component';
import { SubscribeService } from '../../packages/services/subscribe.service';
import { EventsApiService } from '../../../../../api/events-api/events-api.service';
import { MediaApiService } from '../../../../../api/media-api/media-api.service';
import { ChallengesApiService } from '../../../../../api/challenges-api/challenges-api.service';
import { PricesContextService } from '../../../../../context/prices-context/prices-context.service';
import { Price } from '../../../../../dataset/Price';
import { ChallengeClass } from '../../../../../dataset/ChallengeClass';
import { ChallengesContextService } from '../../../../../context/challenges-context/challenges-context.service';
import { MediaContextService } from '../../../../../context/media-context/media-context.service';
import dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  templateUrl: './lesson-video-modal.component.html',
  styleUrls: ['./lesson-video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscribeService],
})
export class LessonVideoModalComponent extends Dialog implements IDialog, OnInit, OnDestroy {
  user: User | null;
  classState: StateClass = 'video';
  lesson: Lesson;
  eventId: number;

  makesEvent: MakesEvent;
  recommendationsResult: RecommendationRequestResult;
  selectedRecommendation: Recommendation;
  requiredParams: RequiredParams;
  feedback: LessonFeedback;
  requiredOptions: RecommendationsRequiredOptions;

  event: ScheduledEvent;
  prices: Price[] | null;

  loading: boolean;

  superBundles: Package[] | null;
  specialBundle: Package | null;
  bundles: Package[] | null;

  activeSuperBundle?: Package;
  activeSpecialBundle: Package | null;
  activePackages: Package[] | null;

  get isVideo(): boolean {
    return this.classState === 'video';
  }

  get isFeedback(): boolean {
    return this.classState === 'feedback';
  }

  get isChallengeDowngrade(): boolean {
    return this.classState === 'challenge-downgrade';
  }

  get isRequiredParams(): boolean {
    return this.classState === 'required_params';
  }

  get isRecommendation(): boolean {
    return this.classState === 'recommendation';
  }

  get isDone(): boolean {
    return this.classState === 'done';
  }

  get isChallengePhoto(): boolean {
    return this.classState === 'challenge-photo';
  }

  get isChallengeComplete(): boolean {
    return this.classState === 'challenge-complete';
  }

  get superMonthOffer(): Package | undefined {
    return this.superBundles?.find(b => this.packagesContext.isMonthlySubscription(b));
  }

  get superYearOffer(): Package | undefined {
    return this.superBundles?.find(b => this.packagesContext.isYearlySubscription(b));
  }

  constructor(
    private mediaContext: MediaContextService,
    private challengesContext: ChallengesContextService,
    private pricesContext: PricesContextService,
    private challengesApiService: ChallengesApiService,
    private mediaApiService: MediaApiService,
    private eventsApiService: EventsApiService,
    private subscribeService: SubscribeService,
    private dialogService: DialogService,
    private packagesContext: PackagesContextService,
    private recommendationsApiService: RecommendationsApiService,
    private cd: ChangeDetectorRef,
    private userContext: UserContextService,
    private i18n: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.eventId = this.data.eventId;
    this.lesson = this.data.lesson;

    combineLatest([
      this.pricesContext.get$(),
      this.userContext.get$(),
      this.packagesContext.getPurePackages$(),
      this.packagesContext.getSuperBundles$(),
      this.packagesContext.getSpecialBundle$(),
      this.packagesContext.getBundles$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([prices, user, packages, superBundles, specialBundle, bundles]) => {
        this.prices = prices;
        this.user = user;
        this.bundles = bundles;
        this.superBundles = superBundles;
        this.specialBundle = specialBundle;

        this.activeSuperBundle = this.superBundles?.find(p => p.is_bought);
        this.activeSpecialBundle =
          this.specialBundle && this.specialBundle.is_bought ? this.specialBundle : null;
        this.activePackages = packages?.filter(p => p.subscription) ?? null;

        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    if (this.cd) {
      this.cd.detach();
    }
  }

  closeModal(event = null): void {
    this.resolve(event);
  }

  challengeDropToRecommendations(challenge: ChallengeClass): void {
    this.loading = true;
    this.challengesApiService
      .leave(+challenge.challenge_id)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.challengesContext.deleteJoinedChallenge(+challenge.challenge_id);
          this.recommendation();
        },
        (err: Error) => {
          this.loading = false;
          alert(err.message);
        }
      );
  }

  classCompleted(makesEvent: MakesEvent): void {
    this.userContext
      .retrieve()
      .pipe(
        tap(user => this.userContext.next(user)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.makesEvent = makesEvent;

        if (this.makesEvent.event.need_photo) {
          this.classState = 'challenge-photo';
        } else {
          this.challengeImage();
        }
        this.cd.detectChanges();
      });
  }

  feedbackComplete(feedback: LessonFeedback): void {
    this.feedback = feedback;
    this.loading = true;

    if (feedback.feedback === 'hard') {
      this.classState = 'challenge-downgrade';
      this.loading = false;
    } else {
      this.closeModal();
    }
    this.cd.detectChanges();
  }

  requiredParamsComplete(options: RecommendationsRequiredOptions): void {
    this.requiredOptions = options;
    this.recommendation();
  }

  recommendation(): void {
    if (!this.feedback) {
      throw new Error('Wrong feedback object');
    }
    this.loading = true;

    const recommendations$ = this.recommendationsApiService.get(
      this.lesson.id,
      this.feedback.feedback,
      this.feedback.comment ?? null,
      this.requiredOptions
    );

    const packages$ = this.packagesContext.getPurePackages$();

    combineLatest([recommendations$, packages$])
      .pipe(
        map(([rec, pack]) => {
          const packMap = keyBy(pack, 'id');
          rec.recommended_start_date = dayjs(
            rec.recommended_start_date,
            'YYYYMMDD[T]HHmmss'
          ).toDate();
          rec.recommendations = rec.recommendations
            ? rec.recommendations.map(r => {
                if ('package' in r && r.package) {
                  r.package = packMap[r.package.id];
                }

                r.recommended_start_date = dayjs(
                  r.recommended_start_date,
                  'YYYYMMDD[T]HHmmss'
                ).toDate();
                return r;
              })
            : [];
          return rec;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.loading = false;
          if (result.required_params) {
            this.requiredParams = result.required_params;
            this.classState = 'required_params';
          } else {
            this.recommendationsResult = result;
            this.classState = 'recommendation';
          }
          this.cd.detectChanges();
        },
        (err: Error) => {
          this.loading = false;
          alert(err.message);
        }
      );
  }

  recommendationComplete(): void {
    if (!this.selectedRecommendation.package?.is_bought) {
      this.subscribe();
    } else {
      this.createEvent(this.selectedRecommendation);
    }
  }

  createEvent(recommendation: Recommendation): void {
    this.loading = true;

    this.createOperation(
      recommendation.recommended_start_date,
      recommendation.lesson.id,
      recommendation.recommendation,
      false
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.classState = 'done';

          this.cd.detectChanges();
        },
        (err: Error) => {
          alert(err.message);
          this.loading = false;
        }
      );
  }

  challengeImage(file?: File): void {
    this.loading = true;
    if (file) {
      this.mediaApiService
        .upload(file, null, this.makesEvent.event.id)
        .pipe(untilDestroyed(this))
        .subscribe(
          media => {
            this.mediaContext.addMedia(media);
            this.classState = this.makesEvent.is_last_challenge_class
              ? 'challenge-complete'
              : 'feedback';
            this.loading = false;
            this.cd.detectChanges();
          },
          (err: Error) => {
            this.loading = false;
            alert(err.message);
          }
        );
    }
    this.classState = this.makesEvent.is_last_challenge_class ? 'challenge-complete' : 'feedback';
    this.loading = false;
    this.cd.detectChanges();
  }

  async subscribe(): Promise<void> {
    const { subscription } = await this.dialogService.open<BuyContent>(BuyModalComponent, {
      color: '#231F1F',
      user: this.user,
      prices: this.prices,
      monthlySubscription: this.superMonthOffer,
      yearlySubscription: this.superYearOffer || this.specialBundle,
      activeBundle: this.activeSuperBundle || this.activeSpecialBundle,
      currentSubscription: this.currentSubscription(),
      activePackages: this.activePackages,
    });

    if (subscription) {
      if (this.user?.hasCompletedTrial) {
        this.userContext.updateUser(this.user, false);
      } else if (this.user?.hasInactiveTrial) {
        this.userContext.updateUser(this.user, false, 'active');
      }
      await this.openSuccess(subscription);
      this.cd.detectChanges();
    }
  }

  async openSuccess(subscription: Subscription): Promise<void> {
    const message = this.subscribeService.messageForSuccess(subscription, this.user);

    await this.dialogService.open(InfoModalComponent, {
      title: 'Success',
      message,
    });
  }

  private createOperation(
    date: any,
    lesson: number,
    rec: number,
    notify: boolean,
    force = false
  ): Observable<ScheduledEvent | unknown> {
    return this.eventsApiService.create(date, lesson, rec, notify, force).pipe(
      tap(() => {
        const user = this.userContext.get();
        this.userContext.updateUserStatus(user);
      }),
      //TODO fix this any
      catchError((err: Error): any => {
        if (err.message === 'Cannot create event. Time is busy.') {
          if (
            confirm(
              this.i18n.instant(
                'Another class is scheduled for this time. Replace it with a new class?'
              )
            )
          ) {
            return this.createOperation(date, lesson, rec, notify, true).pipe(
              map((event: any) => ({ ...event, isNew: true }))
            );
          }
        }
      })
    );
  }

  private currentSubscription(): Package | null {
    return this.bundles?.find(p => p.is_bought) || null;
  }
}
