import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TPoint } from '../../../dataset/TPoint';

const DEFAULT_TIME_STEP = 0;

@UntilDestroy()
@Component({
  selector: 'video-player-controls',
  templateUrl: './video-player-controls.component.html',
  styleUrls: ['./video-player-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerControlsComponent implements OnInit, OnDestroy {
  @ViewChild('playbackProgress', { static: true }) playbackProgress: ElementRef;
  @ViewChild('trackThumb', { static: true }) thumb: ElementRef;

  @Input() video: HTMLVideoElement;
  @Input() fullScreen: boolean;
  @Input() timeline: TPoint[];

  @Input() currentTime: string;
  @Input() duration: string;

  private _progress: number;
  @Input() set progress(val: number) {
    this._progress = val;
    if (this.thumb) {
      this.changeTrackPosition();
    }
  }

  get progress(): number {
    return this._progress;
  }

  @Output() onBack = new EventEmitter<void>();
  @Output() onEndCurrentTime = new EventEmitter<void>();
  @Output() onStartCurrentTime = new EventEmitter<void>();
  @Output() onForward = new EventEmitter<void>();
  @Output() onToggle = new EventEmitter<void>();
  @Output() onFullscreen = new EventEmitter<boolean>();
  @Output() onChangeCurrentTime = new EventEmitter<number>();
  @Output() loadingChange = new EventEmitter<boolean>();

  refreshInterval: number;
  preventSeek = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);

    if (this.cd) {
      this.cd.detach();
    }
  }

  dragElement(el: HTMLElement): void {
    const elementDrag = (e: MouseEvent) => {
      e.preventDefault();
      movedPosition = clickPosition - e.clientX;
      clickPosition = e.clientX;

      if (el.offsetLeft - movedPosition > track.offsetWidth) {
        el.style.left = 100 + '%';
        this.onEndCurrentTime.emit();
      } else if (el.offsetLeft - movedPosition < DEFAULT_TIME_STEP) {
        el.style.left = 0 + '%';
        this.onStartCurrentTime.emit();
      } else {
        el.style.left = ((el.offsetLeft - movedPosition) / track.offsetWidth) * 100 + '%';
        this.onChangeCurrentTime.emit(el.offsetLeft / track.offsetWidth);
      }
      this.cd.detectChanges();
    };

    const closeDragElement = () => {
      document.onmouseup = null;
      document.onmousemove = null;
    };

    const dragMouseDown = (e: MouseEvent) => {
      this.preventSeek = true;
      e.preventDefault();
      clickPosition = e.clientX;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    };

    let movedPosition = 0;
    let clickPosition = 0;
    const track = this.playbackProgress.nativeElement;

    if (document.getElementById(el.id + 'header')) {
      document.getElementById(el.id + 'header')!.onmousedown = dragMouseDown;
    } else {
      el.onmousedown = dragMouseDown;
    }
  }

  seek($event: MouseEvent): void {
    if (this.preventSeek) {
      this.preventSeek = false;
      return;
    }
    this.loadingChange.emit(true);
    const x = ($event.offsetX - 5) / this.playbackProgress.nativeElement.offsetWidth;
    this.video.currentTime = this.video.duration * x;
    this.changeTrackPosition();
  }

  seekBack(): void {
    if (this.video.currentTime !== 0) {
      this.onBack.emit();
      this.changeTrackPosition();
    }
  }

  seekForward(): void {
    if (this.video.currentTime + 15 <= this.video.duration) {
      this.onForward.emit();

      setTimeout(() => {
        this.changeTrackPosition();
      });
    }
  }

  fullScreenHandler(): void {
    this.onFullscreen.emit(!this.fullScreen);
  }

  private init(): void {
    this.dragElement(this.thumb.nativeElement);
    this.cd.markForCheck();
  }

  private changeTrackPosition(): void {
    const track = this.thumb.nativeElement;
    track.style.left = this.progress + '%';
  }
}
