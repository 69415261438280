<div
  class="video-controls st-w-fill"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap.xs.custom="10px"
  fxLayoutGap="20px">
  <!--time controls-->
  <div
    class="time-controls"
    *ngIf="timeline"
    fxLayoutGap="40px"
    fxLayoutGap.md.custom="20px"
    fxLayoutGap.xs.custom="0"
    fxLayout="row">
    <button
      class="control-btn"
      (click)="seekBack()">
      <svg
        width="28"
        height="28">
        <use xlink:href="#back-control"></use>
      </svg>
    </button>

    <button
      class="control-btn"
      (click)="onToggle.emit()">
      <svg
        width="24"
        height="24"
        *ngIf="video.paused; else pauseTemplate">
        <use xlink:href="#play"></use>
      </svg>

      <ng-template #pauseTemplate>
        <svg
          width="24"
          height="24">
          <use xlink:href="#pause-control"></use>
        </svg>
      </ng-template>
    </button>

    <button
      class="control-btn"
      (click)="seekForward()">
      <svg
        width="28"
        height="28">
        <use xlink:href="#next-control"></use>
      </svg>
    </button>
  </div>

  <!--progress-->
  <div
    class="progress-container"
    fxLayout="row"
    fxFlex="1 1"
    role="presentation"
    (click)="seek($event)">
    <div
      class="playback-progress st-w-fill"
      id="playbackProgress"
      #playbackProgress>
      <div
        class="bar"
        [style.width.%]="progress">
      </div>
    </div>

    <div
      id="track-thumb"
      #trackThumb>
      <div id="track-thumb-header"></div>
    </div>
  </div>

  <!--time-->
  <div class="st-color-dark-grey time">
    {{ currentTime }} <span class="st-color-black">/ {{ duration }}</span>
  </div>

  <!--full screen-->
  <button
    (click)="fullScreenHandler()"
    class="control-btn fullscreen-btn">
    <svg
      width="28"
      height="28"
      *ngIf="fullScreen; else fullTemplate">
      <use xlink:href="#minimize"></use>
    </svg>

    <ng-template #fullTemplate>
      <svg
        width="28"
        height="28">
        <use xlink:href="#full-control"></use>
      </svg>
    </ng-template>
  </button>
</div>
