import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DictionaryService } from '../../../../../../services/dictionary/dictionary.service';
import { Router } from '@angular/router';
import { Dialog } from '../../../../../../dataset/Dialog';
import { IDialog } from '../../../../../../shared/components/ui/modals/interfaces/IDialog';
import { ScheduledEvent } from '../../../../../../dataset/ScheduledEvent';
import { ActualChallenge } from '../../../../../../dataset/ActualChallenge';

@Component({
  selector: 'app-lesson-challenge-complete',
  templateUrl: './lesson-challenge-complete.component.html',
  styleUrls: ['./lesson-challenge-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonChallengeCompleteComponent extends Dialog implements IDialog, OnInit {
  constructor(
    private router: Router,
    public dictionary: DictionaryService
  ) {
    super();
  }

  event: ScheduledEvent;
  challenge: ActualChallenge;
  hasAccess: boolean;
  hasActiveSweat: boolean;
  challengeId: number;

  ngOnInit(): void {
    this.event = this.data.event;
    this.hasAccess = this.data.hasAccess;
    this.hasActiveSweat = this.data.hasActiveSweat;
    this.challengeId = this.data.challengeId;
    this.challenge = this.data.challenge;
  }

  async goToChallenges(): Promise<void> {
    this.closeModal();
    await this.router.navigateByUrl('/app/challenges');
  }

  closeModal(data?: string): void {
    this.resolve(data ?? false);
  }

  get calories(): number {
    return this.challenge?.events.reduce((acc, cur) => acc + (cur.calories ?? 0), 0);
  }

  get points(): number {
    return this.challenge?.events.reduce((acc, cur) => acc + (cur.lesson.points ?? 0), 0);
  }

  get minutes(): number {
    return this.challenge?.events.reduce((acc, cur) => acc + (cur?.lesson.minutes ?? 0), 0);
  }
}
