import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonVideoComponent } from './lesson-video.component';
import { IconModule } from '../../../../../../shared/components/ui/icon/icon.module';
import { VideoPlayerModule } from '../../../../../../shared/components/video-player/video-player.module';
import { VideoPlayerControlsModule } from '../../../../../../shared/components/video-player-controls/video-player-controls.module';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [LessonVideoComponent],
  imports: [CommonModule, IconModule, VideoPlayerModule, VideoPlayerControlsModule, FlexModule],
  exports: [LessonVideoComponent],
})
export class LessonVideoModule {}
