import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Value } from '../../../../../../dataset/Value';
import { LessonFeedback, LessonFeedbackValue } from '../../../../../../dataset/LessonFeedback';
import { ScheduledEvent } from '../../../../../../dataset/ScheduledEvent';

@Component({
  selector: 'app-lesson-feedback',
  templateUrl: './lesson-feedback.component.html',
  styleUrls: ['./lesson-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LessonFeedbackComponent {
  @Output() complete: EventEmitter<LessonFeedback> = new EventEmitter();

  @Input() event: ScheduledEvent;

  loading: boolean;

  variants: Value<LessonFeedbackValue>[] = [
    {
      value: 'great',
      label: 'Great',
    },
    {
      value: 'challenge',
      label: 'Challenging',
    },
    {
      value: 'hard',
      label: 'Too hard',
    },
    {
      value: 'easy',
      label: 'Too easy',
    },
  ];

  variant: Value<LessonFeedbackValue>;

  comment: string;

  select(variant: Value<LessonFeedbackValue>): void {
    this.variant = variant;
  }

  selected(variant: LessonFeedbackValue): boolean {
    return this.variant && this.variant.value === variant;
  }

  submit(): void {
    this.complete.emit({
      feedback: this.variant.value,
      comment: this.comment,
    });
  }
}
