<div
  class="video-header"
  fxLayoutGap="10px"
  fxLayoutAlign="space-between center"
  fxLayout="row">
  <div
    class="duration"
    [ngStyle]="{ height: timeLeft ? 'auto' : 0 }">
    {{ timeLeft }}
  </div>

  <div
    fxLayoutGap="30px"
    fxLayout="row">
    <div
      class="calories"
      fxLayoutAlign="start center"
      *ngIf="user && user.growth && user.weight"
      fxLayoutGap="10px"
      fxLayout="row">
      <svg
        width="19"
        height="24"
        class="st-stroke-black">
        <use xlink:href="#flame"></use>
      </svg>

      <div class="calories-value">
        {{ calories ? calories : 0 }}
      </div>
    </div>

    <div class="video-settings">
      <button
        class="video-button"
        #settingsButton
        (click)="toggleOpen()">
        <svg
          class="icon st-stroke-black"
          width="24"
          height="24">
          <use xlink:href="#settings"></use>
        </svg>
      </button>

      <div
        #dropdown
        [class.open]="isOpened"
        fxLayout="column"
        class="settings-dropdown">
        <button
          *ngFor="let video of videoSources"
          class="video-button source-button"
          (click)="onSelectSource(video)"
          [class.active]="videoSource === video">
          {{ video.label }}
        </button>
      </div>
    </div>
  </div>
</div>
