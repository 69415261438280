<div class="video">
  <ng-container *ngIf="videoService.getLoading() | async as loading">
    <app-loading *ngIf="loading" />
  </ng-container>

  <app-video-player-header
    *ngIf="(!error && playing) || (!error && startOnce)"
    [calories]="calories"
    [timeLeft]="timeLeft"
    (selectSource)="selectSource($event)"
    [videoSource]="videoSource"
    [videoSources]="videoSources"
    [user]="user" />

  <div class="video-container">
    <div
      class="video-host"
      [class.error]="error">
      <video
        #_video
        (loadstart)="onLoadStart()"
        (timeupdate)="onTimeUpdate()"
        (loadedmetadata)="onLoadedMetaData()"
        (playing)="onPlaying()"
        (pause)="onPause()"
        (ended)="onEnded()"
        (error)="onError($event)"
        (canplay)="onCanPlay()"
        (waiting)="onWaiting()"
        [src]="videoSourceSrc"
        (dblclick)="doubleClickHandler()"
        (click)="playPauseHandler()">
      </video>

      <div
        class="video-placeholder"
        *ngIf="!playing && !error"
        role="presentation"
        (click)="playVideo()"
        fxLayout="column"
        fxLayoutAlign="center center">
        <div class="video-placeholder-icon">
          <svg
            width="26"
            height="26">
            <use xlink:href="#play"></use>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="error-container"
      *ngIf="error">
      <h3
        class="st-h3"
        [innerHtml]="error | translate">
      </h3>
    </div>
  </div>

  <video-player-controls
    *ngIf="(!error && playing) || (!error && startOnce)"
    [class.hide-controls]="hideControls"
    (loadingChange)="onLoadingChange($event)"
    (onBack)="backHandler()"
    (onForward)="forwardHandler()"
    (onToggle)="playPauseHandler()"
    (onEndCurrentTime)="endCurrentTimeHandler()"
    (onChangeCurrentTime)="changeCurrentTimeHandler($event)"
    (onStartCurrentTime)="startCurrentTimeHandler()"
    (onFullscreen)="fullScreenHandler($event)"
    [timeline]="timeline"
    [progress]="progress"
    [currentTime]="currentTime"
    [duration]="duration"
    [video]="video"
    [fullScreen]="fullScreen" />
</div>
