import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { User } from '../../../../dataset/User';
import { VideoSource } from '../../../../dataset/VideoSource';

@Component({
  selector: 'app-video-player-header',
  templateUrl: './video-player-header.component.html',
  styleUrls: ['./video-player-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerHeaderComponent {
  @Input() user: User | null;
  @Input() calories: number;
  @Input() videoSources: VideoSource[];
  @Input() videoSource: VideoSource;
  @Input() timeLeft: string;

  isOpened = false;
  @ViewChild('dropdown', { static: true })
  private dropdown: ElementRef;

  @ViewChild('settingsButton', { static: true })
  private settingsButton: ElementRef;

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (
      !this.dropdown.nativeElement.contains(event.target) &&
      !this.settingsButton.nativeElement.contains(event.target)
    ) {
      this.isOpened = false;
    }
  }

  @Output() selectSource = new EventEmitter<VideoSource>();

  toggleOpen(): void {
    this.isOpened = !this.isOpened;
  }

  onSelectSource(video: VideoSource) {
    this.selectSource.emit(video);
    this.isOpened = false;
  }
}
