<app-dialog-container
  [state]="state"
  [backdropClose]="false"
  [padding]="'70px 94px'"
  [classNames]="'standard'"
  (close)="closeModal()">
  <div class="completed-challenge">
    <img
      src="https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/medal.png"
      srcset="
        https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/medal.png    1x,
        https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/images/medal@2x.png 2x
      "
      alt="Congrats cup"
      class="completed-challenge__image" />

    <h2 class="st-h2 st-center">
      {{ 'Congrats!' | translate }}
    </h2>

    <p
      class="st-p1 st-center completed-challenge__message"
      *ngIf="event?.name">
      {{ dictionary.messageForChallengeEnding(challenge.program.name, challengeId) }}
    </p>

    <div class="completed-challenge__statistics">
      <span class="completed-challenge__statistics-info">
        <svg
          class="icon st-stroke-black"
          width="24"
          height="24">
          <use xlink:href="#star-2"></use>
        </svg>

        <span>{{ 'Extra points' | translate }}</span>
      </span>

      <span class="completed-challenge__statistics-count"> 300 </span>
    </div>

    <h3 class="st-h3">
      {{ 'Program Summary' | translate }}
    </h3>

    <div class="completed-challenge__statistics">
      <span class="completed-challenge__statistics-info">
        <svg
          class="icon st-stroke-black"
          width="24"
          height="24">
          <use xlink:href="#clock"></use>
        </svg>

        <span>{{ 'Minutes' | translate }}</span>
      </span>

      <span class="completed-challenge__statistics-count">
        {{ minutes }}
      </span>
    </div>

    <div class="completed-challenge__statistics">
      <span class="completed-challenge__statistics-info">
        <svg
          class="icon st-stroke-black"
          width="24"
          height="24">
          <use xlink:href="#flame"></use>
        </svg>

        <span>{{ 'Calories' | translate }}</span>
      </span>

      <span class="completed-challenge__statistics-count">
        {{ calories }}
      </span>
    </div>

    <div class="completed-challenge__statistics last">
      <span class="completed-challenge__statistics-info">
        <svg
          class="icon st-stroke-black"
          width="24"
          height="24">
          <use xlink:href="#star-2"></use>
        </svg>

        <span>{{ 'Points' | translate }}</span>
      </span>

      <span class="completed-challenge__statistics-count">
        {{ points }}
      </span>
    </div>

    <ng-container *ngIf="challengeId === 38 && !hasAccess && hasActiveSweat; else defaultButton">
      <div class="completed-challenge__buttons st-w-fill">
        <button
          class="st-btn st-w-fill st-btn-dark st-weight-700"
          (click)="closeModal()">
          {{ 'See what`s next' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-template #defaultButton>
      <div class="completed-challenge__buttons st-w-fill">
        <button
          class="st-btn st-w-fill st-btn-dark st-weight-700"
          (click)="goToChallenges()">
          {{ 'Join another program' | translate }}
        </button>
      </div>
    </ng-template>
  </div>
</app-dialog-container>
